export const environment = {
    banner: {text: 'REF'},
    personRegisterUrl:
        'https://person-register-be.ref.wsl-bag.ch/',
    volitionUrl:
        'https://public-be.ref.wsl-bag.ch/',
    volitionReadUrl: 'https://query-be.ref.wsl-bag.ch/',
    verificationUrl:
        'https://mail-verification-service.ref.wsl-bag.ch/',
    debugMode: true,
    debugMutationEndpoint: 'https://sedexmock.greenhill-8b27835c.switzerlandnorth.azurecontainerapps.io/zasmock/people/mutation',
    appInsights: {
        instrumentationKey: '353ef5c0-6246-4618-a9fe-c118e455d3ec'
    },
    eiamLoginUrl: 'https://sso.ref.wsl-bag.ch/saml2/authenticate/okta',
    apiBaseURLs: [
        'https://person-register-be.ref.wsl-bag.ch/',
        'https://mail-verification-service.ref.wsl-bag.ch/'
    ]
};
